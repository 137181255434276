import {MutationActionTypes} from '@hy-vee/global-navigation';

import {graphqlClient} from '../graphql/graphql-client';
import {cartForCacheIdentify, cartItemForCacheIdentify} from '../graphql/cache-identify-helpers';
import {cartItemDeleteCartLookupFragment, cartItemQuantityMutateFragment} from '../graphql/fragments/cart-fragments';

const updateCartAfterQuantityChanged = (cartItemId, quantity) => {
    const client = graphqlClient();

    client.writeFragment({
        data: {
            ...cartItemForCacheIdentify(cartItemId),
            quantity,
        },
        fragment: cartItemQuantityMutateFragment,
    });
};

const updateCartAfterItemDelete = (cartItemId) => {
    const client = graphqlClient();
    const cartItemCacheId = client.cache.identify(cartItemForCacheIdentify(cartItemId));

    const cachedCartItem = client.readFragment({
        fragment: cartItemDeleteCartLookupFragment,
        id: cartItemCacheId,
    });

    if (cachedCartItem) {
        const cachedCartId = client.cache.identify(cartForCacheIdentify(cachedCartItem.cartId));

        client.cache.modify({
            fields: {
                cartItems: (existingCartItemRefs, {readField}) =>
                    existingCartItemRefs.filter((cartItemRef) => readField('cartItemId', cartItemRef) !== cartItemId),
            },
            id: cachedCartId,
        });

        client.cache.evict({id: cartItemCacheId});
        client.cache.gc();
    }
};

const storeName = (pickupLocation, cartStore) =>
    cartStore?.name && cartStore?.state ? `${pickupLocation?.name ?? cartStore.name}, ${cartStore.state}` : '';

export const getLocationText = (cart, unauthenticatedStore) => {
    if (cart) {
        const {fulfillmentType, pickupLocation, deliveryAddress, store: cartStore} = cart;

        if (fulfillmentType === 'PICKUP') {
            return storeName(pickupLocation, cartStore);
        } else if (fulfillmentType === 'PICKUP_POINT') {
            return storeName(pickupLocation, cartStore);
        } else if (fulfillmentType === 'DELIVERY') {
            // eslint-disable-next-line unicorn/no-lonely-if
            if (deliveryAddress) {
                return `${deliveryAddress.firstName} - ${deliveryAddress.city}, ${deliveryAddress.state}`;
            }
        }

        return '';
    }

    return storeName(null, unauthenticatedStore);
};

export const handleDataChange = (action) => {
    const {type: actionType, ...data} = action;

    switch (actionType) {
        case MutationActionTypes.changeCartItemQuantity:
            return updateCartAfterQuantityChanged(data.id, data.quantity);
        case MutationActionTypes.removeCartItem:
            return updateCartAfterItemDelete(data.id);
        default:
            return undefined;
    }
};
