import {combineReducers} from 'redux';

import customer from './customer-reducer';
import isAuthenticated from './is-authenticated-reducer';
import store from './store-reducer';

export default () =>
    combineReducers({
        customer,
        isAuthenticated,
        store,
    });
