import {useEffect} from 'react';
import {useQuery} from '@apollo/client';

import {getCustomerAndCartStoreData} from '../../graphql/queries/customer-and-feature-queries';

export const useCustomerAndCartStore = (setCustomerAndStoreData, isAuthenticated, customerId) => {
    const customerAndStoreData = useQuery(getCustomerAndCartStoreData, {
        skip: !isAuthenticated || !customerId,
        variables: {
            customerId,
        },
    });

    useEffect(() => {
        if (
            isAuthenticated &&
            customerAndStoreData.data &&
            customerAndStoreData.data.customer &&
            !customerAndStoreData.loading &&
            !customerAndStoreData.error
        ) {
            setCustomerAndStoreData({
                customer: customerAndStoreData.data.customer,
                store: customerAndStoreData.data.carts[0]?.store || {},
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerAndStoreData]);

    return {
        ...customerAndStoreData,
    };
};
