import GoogleTagManagerWrapper from './views/authentication/google-tag-manager-wrapper';
import CustomerWrapper from './views/authentication/customer-wrapper';

const PerksWeb = (props) => (
    <GoogleTagManagerWrapper>
        <CustomerWrapper setCustomerAndStoreData={props.setCustomerAndStoreData}>
            {props.children}
        </CustomerWrapper>
    </GoogleTagManagerWrapper>
);

export default PerksWeb;
