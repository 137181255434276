import fetchService from './fetch-service';

export const loadCoupon = async (couponId) =>
    // eslint-disable-next-line no-return-await
    await fetchService(`/perks/api/coupons/${couponId}/load`, {
        body: JSON.stringify({
            couponId,
        }),
        method: 'POST',
    });
