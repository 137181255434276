import {useSelector} from 'react-redux';

import {withApollo} from '../../graphql/with-apollo';

import {useCustomerAndCartStore} from './useCustomerAndCartStore';

const CustomerWrapper = ({setCustomerAndStoreData, children}) => {
    const {customer, isAuthenticated} = useSelector((state) => ({
        customer: state.customer,
        isAuthenticated: state.isAuthenticated,
    }));
    const customerAndStoreData = useCustomerAndCartStore(setCustomerAndStoreData, isAuthenticated, customer?.customerId);

    if (!isAuthenticated) {
        return children;
    }

    return !customerAndStoreData.loading ? children : null;
};

export default withApollo(CustomerWrapper);
