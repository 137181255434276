import {FOUND} from 'http-status';

export const redirectToPath = (path, res) => {
    if (res) {
        res.writeHead(FOUND, {Location: path});
        res.end();
    } else {
        window.location.assign(path);
    }
};
