import {isServerSide} from '../lib/env';
import {createStore} from '../services/redux-store-service';

export const __NEXT_REDUX_STORE__ = '__NEXT_REDUX_STORE__';

export const getOrCreateStore = (initialState) => {
    if (isServerSide()) {
        return createStore(initialState);
    }

    if (!window[__NEXT_REDUX_STORE__]) {
        window[__NEXT_REDUX_STORE__] = createStore(initialState);
    }

    return window[__NEXT_REDUX_STORE__];
};
