// eslint-disable-next-line no-unused-vars
import React, {useEffect, useState} from 'react';

import AuthIframe from '../views/auth-iframe';

const checkAuthentication = (props) => props.isAuthenticated || props.pageProps?.reduxState?.isAuthenticated;

const withMixedAuthenticationCheck = (WrappedComponent) => {
    const WithMixedAuthenticationCheck = (props) => {
        const [listenerReady, setListenerReady] = useState(false);

        useEffect(() => {
            const handleMessage = (event) => {
                if (event.data === 'auth-checked') {
                    const isAuthenticated = checkAuthentication(props);

                    window.removeEventListener('message', handleMessage);

                    if (!isAuthenticated) {
                        window.location.reload();
                    }
                }
            };

            window.addEventListener('message', handleMessage);

            setListenerReady(true);

            return () => {
                window.removeEventListener('message', handleMessage);
                setListenerReady(false);
            };

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        const isAuthenticated = checkAuthentication(props);

        return (
            <>
                <WrappedComponent {...props} />
                {!isAuthenticated && listenerReady && <AuthIframe />}
            </>
        );
    };

    return WithMixedAuthenticationCheck;
};

export default withMixedAuthenticationCheck;
