import {
    SET_CUSTOMER,
} from '../action-types';

import {createReducer} from './reducer-factory';
import updateObject from './handler-functions/update-object';

const handlers = {
    [SET_CUSTOMER]: updateObject('customer'),
};

export default createReducer({}, handlers);
