// eslint-disable-next-line no-unused-vars
import React from 'react';
import {useSelector} from 'react-redux';

const addUserContextToGTM = (isAuthenticated, customerUuid) => {
    const checks = [
        typeof global.dataLayer !== 'undefined',
        typeof customerUuid !== 'undefined',
        Array.isArray(global.dataLayer),
        isAuthenticated === true,
    ];

    if (checks.every(Boolean)) {
        global.dataLayer.push({userId: customerUuid});
    }
};

const GoogleTagManagerWrapper = ({children}) => {
    const isAuthenticated = useSelector((state) => state.isAuthenticated);
    const customerUuid = useSelector((state) => state?.customer?.customerUuid);
    const iframeStyle = {
        display: 'none',
        visibility: 'hidden',
    };

    addUserContextToGTM(isAuthenticated, customerUuid);

    return (
        <span>
            <iframe
                aria-hidden="true"
                height="0"
                src="//www.googletagmanager.com/ns.html?id=GTM-5TL68P"
                style={iframeStyle}
                title="Google Tag Manager"
                width="0"
            />
            {children}
        </span>
    );
};

export default GoogleTagManagerWrapper;
