import {OK} from 'http-status';

import fetch from '../services/fetch-service';
import {SET_CUSTOMER, SET_AUTHENTICATION_SUCCESS} from '../action-types';

export const getInitialState = (request) => async (dispatch) => {
    const options = {};

    try {
        const response = await fetch('http://localhost:3000/perks/api', options, request);

        if (response.status === OK) {
            const {customer, isAuthenticated} = await response.json();

            dispatch({
                customer,
                type: SET_CUSTOMER,
            });
            dispatch({
                isAuthenticated,
                type: SET_AUTHENTICATION_SUCCESS,
            });
        }
    } catch {}
};
