export default (url, options, passedThroughRequest) => {
    const defaultOptions = {
        credentials: 'same-origin',
        headers: {
            'content-type': 'application/json',
        },
        method: 'GET',
    };

    if (passedThroughRequest?.headers?.cookie) {
        defaultOptions.headers.cookie = passedThroughRequest.headers.cookie;
    }

    return fetch(url, {
        ...defaultOptions,
        ...options,
    }).then((response) => {
        if (!response.ok) {
            const err = new Error(`Error fetching ${url}: ${response.status}`);

            err.response = response;

            throw err;
        }

        return response;
    });
};
