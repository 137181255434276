import * as queryString from 'query-string';

import {loadCoupon} from '../services/coupons-service';

const LOGIN_PATH = '/perks/login';

const returnUrl = (pathWithQuery) =>
    queryString.stringify({
        redirect: pathWithQuery,
    });

const getLoginUrl = (path) => `${LOGIN_PATH}?${returnUrl(path)}`;

export const login = (path) => {
    const pathWithRedirect = getLoginUrl(path);

    window.location.assign(pathWithRedirect);
};

export const clipCoupon = (couponId, setState) => async () => {
    let clipped = false;

    try {
        setState('loading');
        await loadCoupon(couponId);
        setState('Activated');

        clipped = true;
    } catch {}

    return clipped;
};

export const seeCouponDetails = (couponId) => {
    window.location.assign(`/deals/coupons/${couponId}`);
};
