import {createStore as reduxCreateStore, applyMiddleware} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import fetchMiddleware from 'redux-modular-fetch-middleware';

import reducers from '../reducers';

import fetchService from './fetch-service';

export const createStore = (initialState) =>
    reduxCreateStore(
        reducers(),
        initialState,
        composeWithDevTools(applyMiddleware(thunk, fetchMiddleware(fetchService))),
    );
