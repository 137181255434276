import {
    SET_STORE,
} from '../action-types';

import {createReducer} from './reducer-factory';
import updateObject from './handler-functions/update-object';

const handlers = {
    [SET_STORE]: updateObject('store'),
};

export default createReducer({}, handlers);
