import {SET_CUSTOMER, SET_STORE} from '../action-types';

export const setCustomerAndStoreData = ({customer, store}) => (dispatch) => {
    dispatch({
        customer,
        type: SET_CUSTOMER,
    });

    dispatch({
        store,
        type: SET_STORE,
    });
};

export const setCustomerData = ({customer}) => (dispatch) => {
    dispatch({
        customer,
        type: SET_CUSTOMER,
    });
};

